import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import CopyrightNote from '../components/CopyrightNote'

const ContactSuccessPage = () => (
  <Layout navVisible={false}>
    <div id="main">
      <section id="contact-success" className="main">
        <span className="icon fa-check-circle-o"></span>
        <h2>Thanks for reaching out!</h2>
        <p>We have received your message and will be getting back to you shortly. Have a great day!</p>
        <Link to="/" className="button">Back to Home</Link>
        <footer id="footer" className="major">
          <CopyrightNote />
        </footer>
      </section>
    </div>
  </Layout>
)

export default ContactSuccessPage
